// ALL possible fields in the rentroll data
import {
  MappableUnitFields,
  MappableAssetFields,
  ExportableUnitFields,
  ExportableAssetFields
} from "@moodys/cre-cpm-client-apis.apis.services.auth"

export const unitFields = [
  "rentroll_date",
  "source_portfolio_ref_id",
  "source_portfolio_name",
  "portfolio_ref_uuid",
  "source_unit_ref_id",
  "unit_ref_uuid",
  "source_asset_ref_id",
  "asset_ref_uuid",
  "source_tenant_ref_id",
  "tenant_ref_uuid",
  "source_building_ref_id",
  "source_lease_ref_id",
  "currency_code",
  "measured_by",
  "source_address",
  "source_street",
  "source_street_number",
  "source_postal_code",
  "source_city",
  "country_code",
  "normalized_address",
  "lonlat",
  "longitude",
  "latitude",
  "source_type_of_use",
  "type_of_use",
  "type_of_use_2",
  "comment_other",
  "source_area",
  "area",
  "source_num_parking_spaces",
  "num_parking_spaces",
  "source_num_rooms",
  "num_rooms",
  "part_of_building",
  "floor_number",
  "floor_location",
  "condominium",
  "source_rented_status",
  "rented_status",
  "rented_status_terminated",
  "rented_status_termination_effective_date",
  "comment_vacancy",
  "tenant_name",
  "tenant_name_2",
  "tenant_group",
  "tenant_corporate_group",
  "tenant_industry",
  "tenant_sub_industry",
  "tenant_main_customer",
  "tenant_address",
  "lease_start_date",
  "lease_end_date",
  "walt",
  "lease_signed_date",
  "lease_next_possible_end_date",
  "lease_next_possible_end_notice_date",
  "lease_notice_period",
  "automatic_renewal_term",
  "automatic_renewal_notice_date",
  "automatic_renewal_notice",
  "source_rent_annual",
  "source_rent_quarterly",
  "source_rent_monthly",
  "rent_monthly",
  "source_rent_parking_annual",
  "source_rent_parking_quarterly",
  "source_rent_parking_monthly",
  "rent_vat",
  "rent_vat_tax_rate",
  "rent_parking_monthly",
  "rent_other_income",
  "rent_free_start_date",
  "rent_free_end_date",
  "rent_reduction",
  "rent_reduction_start_date",
  "rent_reduction_end_date",
  "term_opex",
  "term_heatex",
  "term_service_charge",
  "term_other_service_charges",
  "arrear_amount",
  "arrear_since",
  "arrear_dunning_level",
  "index_text",
  "index_type",
  "index_base_year",
  "index_waiting_period",
  "index_interval",
  "last_indexation_date",
  "next_indexation_date",
  "index_hurdle",
  "index_hurdle_type",
  "index_hurdle_last",
  "index_pass_on",
  "rent_steps_text",
  "step_up_1",
  "step_up_amount_1",
  "step_up_monthly_rent_1",
  "step_up_percent_1",
  "step_up_2",
  "step_up_amount_2",
  "step_up_monthly_rent_2",
  "step_up_percent_2",
  "step_up_3",
  "step_up_amount_3",
  "step_up_monthly_rent_3",
  "step_up_percent_3",
  "step_up_4",
  "step_up_amount_4",
  "step_up_monthly_rent_4",
  "step_up_percent_4",
  "step_up_5",
  "step_up_amount_5",
  "step_up_monthly_rent_5",
  "step_up_percent_5",
  "step_up_6",
  "step_up_amount_6",
  "step_up_monthly_rent_6",
  "step_up_percent_6",
  "step_up_7",
  "step_up_amount_7",
  "step_up_monthly_rent_7",
  "step_up_percent_7",
  "step_up_8",
  "step_up_amount_8",
  "step_up_monthly_rent_8",
  "step_up_percent_8",
  "step_up_9",
  "step_up_amount_9",
  "step_up_monthly_rent_9",
  "step_up_percent_9",
  "step_up_10",
  "step_up_amount_10",
  "step_up_monthly_rent_10",
  "step_up_percent_10",
  "last_increase_date",
  "break_options_text",
  "break_option_date_1",
  "break_option_notice_month_1",
  "break_option_notice_1",
  "break_option_type_1",
  "break_option_notes_1",
  "break_option_date_2",
  "break_option_notice_month_2",
  "break_option_notice_2",
  "break_option_type_2",
  "break_option_notes_2",
  "break_option_date_3",
  "break_option_notice_month_3",
  "break_option_notice_3",
  "break_option_type_3",
  "break_option_notes_3",
  "term_options_text",
  "option_notice_1",
  "option_notice_month_1",
  "option_start_1",
  "option_end_1",
  "option_term_1",
  "rent_after_option_1",
  "option_taken_1",
  "option_type_1",
  "option_notes_1",
  "option_notice_2",
  "option_notice_month_2",
  "option_start_2",
  "option_end_2",
  "option_term_2",
  "rent_after_option_2",
  "option_taken_2",
  "option_type_2",
  "option_notes_2",
  "option_notice_3",
  "option_notice_month_3",
  "option_start_3",
  "option_end_3",
  "option_term_3",
  "rent_after_option_3",
  "option_taken_3",
  "option_type_3",
  "option_notes_3",
  "option_notice_4",
  "option_notice_month_4",
  "option_start_4",
  "option_end_4",
  "option_term_4",
  "rent_after_option_4",
  "option_taken_4",
  "option_type_4",
  "option_notes_4",
  "option_notice_5",
  "option_notice_month_5",
  "option_start_5",
  "option_end_5",
  "option_term_5",
  "rent_after_option_5",
  "option_taken_5",
  "option_type_5",
  "option_notes_5",
  "deposit_type",
  "deposit_agreed",
  "deposit_receipt",
  "subsidy",
  "competition_clause",
  "created_at",
  "updated_at",
  "currency_exchange_rates",
  "market_rent",
  "city_id",
  "district_id",
  "source_unit_type",
  "unit_type",
  "parent_unit_id",
  "com_base_rent",
  "com_rent_free",
  "com_cam",
  "com_utilities",
  "com_water",
  "com_real_estate_taxes",
  "com_insurance",
  "com_mgmt_fee",
  "com_janitorial",
  "com_reimbursements",
  "com_other",
  "mf_rent",
  "mf_subsidy",
  "subsidy_date",
  "mf_concessions",
  "mf_parking",
  "mf_storage",
  "mf_pet",
  "mf_cable_internet",
  "mf_utilities",
  "mf_water",
  "mf_other",
  "mf_trash",
  "mf_amenity",
  "mf_nru",
  "mf_reimbursements",
  "percentage_rent_amount",
  "percentage_rent_breakpoint",
  "percentage_rent_rate",
  "annual_sales",
  "lease_type",
  "expense_stop",
  "occupancy_cost",
  "tenant_improvement_allowance",
  "leasing_commission",
  "leasing_commision_percent",
  "month_to_month_flag",
  "source_level_of_care_senior_housing",
  "source_is_private_senior_housing",
  "mf_care_charges",
  "mf_second_resident_charge"
] as MappableUnitFields[] | ExportableUnitFields[]

export const assetFields = [
  "rentroll_date",
  "source_portfolio_ref_id",
  "source_portfolio_name",
  "portfolio_ref_uuid",
  "subportfolio",
  "source_asset_ref_id",
  "source_asset_erp_id",
  "asset_ref_uuid",
  "cluster_portfolio",
  "cluster_asset",
  "asset_name",
  "project_name",
  "spv",
  "spv_id",
  "seller_spv",
  "manager_portfolio",
  "manager_asset",
  "manager_property",
  "currency_code",
  "currency_exchange_rates",
  "measured_by",
  "asset_status",
  "source_main_type_of_use",
  "main_type_of_use",
  "source_address",
  "source_street",
  "source_street_number",
  "source_postal_code",
  "source_city",
  "country_code",
  "normalized_address",
  "lonlat",
  "longitude",
  "latitude",
  "total_net_rent_monthly",
  "walt",
  "vacancy_area",
  "vacancy_percent",
  "main_tenant",
  "total_area",
  "total_units",
  "building_type",
  "number_of_buildings",
  "source_construction_year",
  "construction_year",
  "source_refurbishment_year",
  "refurbishment_year",
  "source_refurbishment_degree",
  "refurbishment_degree",
  "capex_invested",
  "ti_invested_units",
  "ti_invested_area",
  "ti_invested_amount",
  "units_refurbished",
  "units_partly_refurbished",
  "units_unrefurbished",
  "roof_developed",
  "facade_insulated",
  "windows_insulated",
  "heating",
  "cadastral_map",
  "land_register",
  "floor_cadastral",
  "land_parcel",
  "plot_size",
  "leasehold_term_date",
  "has_public_subsidy",
  "public_subsidy_type",
  "public_subsidy_amount",
  "public_subsidy_term_date",
  "development_expenses",
  "gross_floor_area",
  "net_operating_income",
  "net_operating_income_date",
  "energy_certificate_info",
  "has_energy_certificate",
  "elevator_info",
  "has_elevator",
  "balcony_info",
  "has_balcony",
  "sole_ownership_info",
  "has_sole_ownership",
  "partial_ownership_info",
  "has_partial_ownership",
  "ab_existent_info",
  "has_ab_existent",
  "leasehold_info",
  "has_leasehold",
  "group_rent_yearly",
  "easements_info",
  "has_easements",
  "flood_area_info",
  "has_flood_area",
  "development_area_info",
  "has_development_area",
  "redevelopment_area_info",
  "has_redevelopment_area",
  "urban_reconstruction_area_info",
  "has_urban_reconstruction_area",
  "preservation_statute_info",
  "has_preservation_statute",
  "construction_law_info",
  "has_construction_law",
  "listed_property_info",
  "has_listed_property",
  "building_encumbrance_info",
  "has_building_encumbrance",
  "contamination_info",
  "has_contamination",
  "undeveloped",
  "index_floor_space",
  "index_site_occupancy",
  "land_use_plan",
  "construction_status",
  "construction_date",
  "asking_price_date",
  "asking_price",
  "acquisition_date",
  "acquisition_price_net",
  "acquisition_side_costs",
  "acquisition_transfer_of_ownership",
  "sales_date",
  "sales_price_net",
  "sales_transfer_of_ownership",
  "market_valuation_date",
  "market_value",
  "market_rent_appraisal",
  "created_at",
  "updated_at",
  "state_name",
  "zipcode",
  "cap_rate",
  "market_name",
  "district_name"
] as MappableAssetFields[] | ExportableAssetFields[]

// FIELDS AFFECTING CHARTS
// These have a red saterisk in the table

export const unitFieldsAffectingCharts = [
  "rentroll_date",
  "source_portfolio_name",
  "portfolio_ref_uuid",
  "unit_ref_uuid",
  "asset_ref_uuid",
  "normalized_address",
  "type_of_use",
  "area",
  "rented_status",
  "rented_status_terminated",
  "tenant_name",
  "rent_monthly",
  "source_unit_type",
  "unit_type"
]

export const assetFieldsAffectingCharts = ["vacancy_percent", "construction_year"]

// NON MAPPABLE FIELDS
// note: all fields are exportable
// these are disabled in the table

export const assetNonMappableFields = [
  "rentroll_date",
  "source_portfolio_ref_id",
  "source_portfolio_name",
  "portfolio_ref_uuid",
  "source_asset_ref_id",
  "asset_ref_uuid",
  "currency_code",
  "currency_exchange_rates",
  "measured_by",
  "main_type_of_use",
  "source_address",
  "source_street",
  "source_street_number",
  "source_postal_code",
  "source_city",
  "country_code",
  "normalized_address",
  "lonlat",
  "longitude",
  "latitude",
  "total_net_rent_monthly",
  "walt",
  "vacancy_area",
  "vacancy_percent",
  "main_tenant",
  "total_area",
  "total_units",
  "construction_year",
  "refurbishment_year",
  "created_at",
  "updated_at",
  "state_name",
  "zipcode",
  "market_name",
  "district_name"
]

// list of fields that are not mappable for units
// these are disabled and not checked in the table

export const unitNonMappableFields = [
  "portfolio_ref_uuid",
  "unit_ref_uuid",
  "asset_ref_uuid",
  "tenant_ref_uuid",
  "country_code",
  "normalized_address",
  "lonlat",
  "longitude",
  "latitude",
  "type_of_use",
  "area",
  "num_parking_spaces",
  "num_rooms",
  "rented_status",
  "walt",
  "rent_monthly",
  "rent_parking_monthly",
  "created_at",
  "updated_at",
  "currency_exchange_rates",
  "city_id",
  "district_id",
  "unit_type",
  "parent_unit_id",
  "source_level_of_care_senior_housing",
  "source_is_private_senior_housing",
  "mf_care_charges",
  "mf_second_resident_charge"
]

// the company endpoint returns an empty array for fields if all fields in this company are SET for mapping or exporting
// so the DEFAULT arrays are created with fields that do NOT have a checkmark in the excel
// when default is selected, the below fieds are unchecked

// ASSET DEFAUTLS FOR MAPPING AND EXPORTING

const assetDefaultMappableFieldsUS = [
  "source_portfolio_ref_id",
  "source_portfolio_name",
  "portfolio_ref_uuid",
  "subportfolio",
  "source_asset_erp_id",
  "asset_ref_uuid",
  "cluster_portfolio",
  "cluster_asset",
  "project_name",
  "spv",
  "spv_id",
  "seller_spv",
  "manager_portfolio",
  "manager_asset",
  "manager_property",
  "currency_code",
  "currency_exchange_rates",
  "measured_by",
  "source_street",
  "source_street_number",
  "source_postal_code",
  "source_city",
  "country_code",
  "lonlat",
  "longitude",
  "latitude",
  "total_net_rent_monthly",
  "walt",
  "source_construction_year",
  "construction_year",
  "source_refurbishment_year",
  "refurbishment_year",
  "source_refurbishment_degree",
  "refurbishment_degree",
  "capex_invested",
  "ti_invested_units",
  "ti_invested_area",
  "ti_invested_amount",
  "units_refurbished",
  "units_partly_refurbished",
  "units_unrefurbished",
  "roof_developed",
  "facade_insulated",
  "windows_insulated",
  "heating",
  "cadastral_map",
  "land_register",
  "floor_cadastral",
  "land_parcel",
  "plot_size",
  "leasehold_term_date",
  "has_public_subsidy",
  "public_subsidy_type",
  "public_subsidy_amount",
  "public_subsidy_term_date",
  "development_expenses",
  "gross_floor_area",
  "net_operating_income",
  "net_operating_income_date",
  "energy_certificate_info",
  "has_energy_certificate",
  "elevator_info",
  "has_elevator",
  "balcony_info",
  "has_balcony",
  "sole_ownership_info",
  "has_sole_ownership",
  "partial_ownership_info",
  "has_partial_ownership",
  "ab_existent_info",
  "has_ab_existent",
  "leasehold_info",
  "has_leasehold",
  "group_rent_yearly",
  "easements_info",
  "has_easements",
  "flood_area_info",
  "has_flood_area",
  "development_area_info",
  "has_development_area",
  "redevelopment_area_info",
  "has_redevelopment_area",
  "urban_reconstruction_area_info",
  "has_urban_reconstruction_area",
  "preservation_statute_info",
  "has_preservation_statute",
  "construction_law_info",
  "has_construction_law",
  "listed_property_info",
  "has_listed_property",
  "building_encumbrance_info",
  "has_building_encumbrance",
  "contamination_info",
  "has_contamination",
  "undeveloped",
  "index_floor_space",
  "index_site_occupancy",
  "land_use_plan",
  "construction_status",
  "construction_date",
  "asking_price_date",
  "asking_price",
  "acquisition_date",
  "acquisition_price_net",
  "acquisition_side_costs",
  "acquisition_transfer_of_ownership",
  "sales_date",
  "sales_price_net",
  "sales_transfer_of_ownership",
  "market_valuation_date",
  "market_value",
  "market_rent_appraisal",
  "created_at",
  "updated_at",
  "state_name",
  "zipcode",
  "cap_rate",
  "market_name",
  "district_name"
]

const assetDefaultMappableFieldsEU = [
  "rentroll_date",
  "source_portfolio_ref_id",
  "source_portfolio_name",
  "portfolio_ref_uuid",
  "source_asset_ref_id",
  "asset_ref_uuid",
  "project_name",
  "seller_spv",
  "manager_portfolio",
  "manager_asset",
  "manager_property",
  "currency_code",
  "currency_exchange_rates",
  "measured_by",
  "main_type_of_use",
  "source_address",
  "source_street",
  "source_street_number",
  "source_postal_code",
  "source_city",
  "country_code",
  "normalized_address",
  "lonlat",
  "longitude",
  "latitude",
  "total_net_rent_monthly",
  "walt",
  "vacancy_area",
  "vacancy_percent",
  "main_tenant",
  "total_area",
  "total_units",
  "number_of_buildings",
  "construction_year",
  "refurbishment_year",
  "capex_invested",
  "ti_invested_units",
  "ti_invested_area",
  "ti_invested_amount",
  "units_refurbished",
  "units_partly_refurbished",
  "units_unrefurbished",
  "roof_developed",
  "facade_insulated",
  "windows_insulated",
  "heating",
  "cadastral_map",
  "land_register",
  "floor_cadastral",
  "land_parcel",
  "has_public_subsidy",
  "public_subsidy_type",
  "public_subsidy_amount",
  "public_subsidy_term_date",
  "development_expenses",
  "gross_floor_area",
  "energy_certificate_info",
  "has_energy_certificate",
  "elevator_info",
  "has_elevator",
  "balcony_info",
  "has_balcony",
  "sole_ownership_info",
  "has_sole_ownership",
  "partial_ownership_info",
  "has_partial_ownership",
  "ab_existent_info",
  "has_ab_existent",
  "easements_info",
  "has_easements",
  "flood_area_info",
  "has_flood_area",
  "development_area_info",
  "has_development_area",
  "redevelopment_area_info",
  "has_redevelopment_area",
  "urban_reconstruction_area_info",
  "has_urban_reconstruction_area",
  "preservation_statute_info",
  "has_preservation_statute",
  "construction_law_info",
  "has_construction_law",
  "listed_property_info",
  "has_listed_property",
  "building_encumbrance_info",
  "has_building_encumbrance",
  "contamination_info",
  "has_contamination",
  "undeveloped",
  "index_floor_space",
  "index_site_occupancy",
  "land_use_plan",
  "construction_status",
  "construction_date",
  "market_rent_appraisal",
  "created_at",
  "updated_at",
  "state_name",
  "zipcode",
  "market_name",
  "district_name"
]

const assetDefaultExportableFieldsUS = [
  "rentroll_date",
  "source_asset_ref_id",
  "asset_name",
  "asset_status",
  "source_main_type_of_use",
  "main_type_of_use",
  "source_address",
  "normalized_address",
  "vacancy_area",
  "vacancy_percent",
  "main_tenant",
  "total_area",
  "total_units",
  "building_type",
  "number_of_buildings"
]

export const assetDefaultExportableFieldsEU = [] // All fiels are selected by default for assetDefaultExportableFieldsEU

// UNIT DEFAUTLS FOR MAPPING AND EXPORTING

const unitDefaultMappableFieldsUS = [
  "source_portfolio_ref_id",
  "source_portfolio_name",
  "portfolio_ref_uuid",
  "unit_ref_uuid",
  "asset_ref_uuid",
  "source_tenant_ref_id",
  "tenant_ref_uuid",
  "source_building_ref_id",
  "source_lease_ref_id",
  "currency_code",
  "measured_by",
  "source_street",
  "source_street_number",
  "source_postal_code",
  "source_city",
  "country_code",
  "normalized_address",
  "lonlat",
  "longitude",
  "latitude",
  "type_of_use",
  "type_of_use_2",
  "comment_other",
  "area",
  "source_num_parking_spaces",
  "num_parking_spaces",
  "source_num_rooms",
  "num_rooms",
  "part_of_building",
  "floor_number",
  "floor_location",
  "condominium",
  "rented_status",
  "rented_status_terminated",
  "rented_status_termination_effective_date",
  "comment_vacancy",
  "tenant_group",
  "tenant_corporate_group",
  "tenant_industry",
  "tenant_sub_industry",
  "tenant_main_customer",
  "tenant_address",
  "walt",
  "lease_signed_date",
  "lease_next_possible_end_date",
  "lease_next_possible_end_notice_date",
  "lease_notice_period",
  "automatic_renewal_term",
  "automatic_renewal_notice_date",
  "automatic_renewal_notice",
  "rent_monthly",
  "source_rent_parking_annual",
  "source_rent_parking_quarterly",
  "source_rent_parking_monthly",
  "rent_vat",
  "rent_vat_tax_rate",
  "rent_parking_monthly",
  "rent_free_start_date",
  "rent_free_end_date",
  "rent_reduction",
  "rent_reduction_start_date",
  "rent_reduction_end_date",
  "term_opex",
  "term_heatex",
  "term_service_charge",
  "term_other_service_charges",
  "arrear_amount",
  "arrear_since",
  "arrear_dunning_level",
  "index_text",
  "index_type",
  "index_base_year",
  "index_waiting_period",
  "index_interval",
  "last_indexation_date",
  "next_indexation_date",
  "index_hurdle",
  "index_hurdle_type",
  "index_hurdle_last",
  "index_pass_on",
  "last_increase_date",
  "term_options_text",
  "deposit_type",
  "deposit_agreed",
  "subsidy",
  "competition_clause",
  "created_at",
  "updated_at",
  "currency_exchange_rates",
  "city_id",
  "district_id",
  "unit_type",
  "parent_unit_id"
]

const unitDefaultMappableFieldsEU = [
  "rentroll_date",
  "portfolio_ref_uuid",
  "unit_ref_uuid",
  "asset_ref_uuid",
  "tenant_ref_uuid",
  "currency_code",
  "measured_by",
  "country_code",
  "normalized_address",
  "lonlat",
  "longitude",
  "latitude",
  "type_of_use",
  "comment_other",
  "area",
  "num_parking_spaces",
  "num_rooms",
  "condominium",
  "rented_status",
  "rented_status_terminated",
  "rented_status_termination_effective_date",
  "comment_vacancy",
  "tenant_group",
  "tenant_corporate_group",
  "tenant_industry",
  "tenant_sub_industry",
  "tenant_main_customer",
  "tenant_address",
  "walt",
  "lease_signed_date",
  "rent_monthly",
  "rent_vat_tax_rate",
  "rent_parking_monthly",
  "arrear_amount",
  "arrear_since",
  "arrear_dunning_level",
  "last_increase_date",
  "break_option_notes_1",
  "break_option_notes_2",
  "break_option_notes_3",
  "rent_after_option_1",
  "option_taken_1",
  "option_notes_1",
  "rent_after_option_2",
  "option_taken_2",
  "option_notes_2",
  "rent_after_option_3",
  "option_taken_3",
  "option_notes_3",
  "rent_after_option_4",
  "option_taken_4",
  "option_notes_4",
  "rent_after_option_5",
  "option_taken_5",
  "option_notes_5",
  "deposit_type",
  "deposit_agreed",
  "subsidy",
  "competition_clause",
  "created_at",
  "updated_at",
  "currency_exchange_rates",
  "market_rent",
  "city_id",
  "district_id",
  "source_unit_type",
  "unit_type",
  "parent_unit_id",
  "com_base_rent",
  "com_rent_free",
  "com_cam",
  "com_utilities",
  "com_water",
  "com_real_estate_taxes",
  "com_insurance",
  "com_mgmt_fee",
  "com_janitorial",
  "com_reimbursements",
  "com_other",
  "mf_rent",
  "mf_subsidy",
  "subsidy_date",
  "mf_concessions",
  "mf_parking",
  "mf_storage",
  "mf_pet",
  "mf_cable_internet",
  "mf_utilities",
  "mf_water",
  "mf_other",
  "mf_trash",
  "mf_amenity",
  "mf_nru",
  "mf_reimbursements",
  "percentage_rent_amount",
  "percentage_rent_breakpoint",
  "percentage_rent_rate",
  "annual_sales",
  "lease_type",
  "expense_stop",
  "occupancy_cost",
  "tenant_improvement_allowance",
  "leasing_commission",
  "leasing_commision_percent",
  "month_to_month_flag"
]

const unitDefaultExportableFieldsUS = [
  "source_portfolio_ref_id",
  "source_portfolio_name",
  "portfolio_ref_uuid",
  "unit_ref_uuid",
  "asset_ref_uuid",
  "source_tenant_ref_id",
  "tenant_ref_uuid",
  "source_building_ref_id",
  "source_lease_ref_id",
  "currency_code",
  "measured_by",
  "source_street",
  "source_street_number",
  "source_postal_code",
  "source_city",
  "country_code",
  "lonlat",
  "longitude",
  "latitude",
  "comment_other",
  "source_num_parking_spaces",
  "num_parking_spaces",
  "part_of_building",
  "floor_number",
  "floor_location",
  "condominium",
  "rented_status_terminated",
  "rented_status_termination_effective_date",
  "comment_vacancy",
  "tenant_group",
  "tenant_corporate_group",
  "tenant_industry",
  "tenant_sub_industry",
  "tenant_main_customer",
  "tenant_address",
  "walt",
  "lease_signed_date",
  "lease_next_possible_end_date",
  "lease_next_possible_end_notice_date",
  "lease_notice_period",
  "automatic_renewal_term",
  "automatic_renewal_notice_date",
  "automatic_renewal_notice",
  "source_rent_annual",
  "source_rent_quarterly",
  "source_rent_parking_annual",
  "source_rent_parking_quarterly",
  "source_rent_parking_monthly",
  "rent_vat",
  "rent_vat_tax_rate",
  "rent_parking_monthly",
  "rent_free_start_date",
  "rent_free_end_date",
  "rent_reduction",
  "rent_reduction_start_date",
  "rent_reduction_end_date",
  "term_opex",
  "term_heatex",
  "term_service_charge",
  "term_other_service_charges",
  "arrear_amount",
  "arrear_since",
  "arrear_dunning_level",
  "index_text",
  "index_type",
  "index_base_year",
  "index_waiting_period",
  "index_interval",
  "last_indexation_date",
  "next_indexation_date",
  "index_hurdle",
  "index_hurdle_type",
  "index_hurdle_last",
  "index_pass_on",
  "last_increase_date",
  "break_option_notes_1",
  "break_option_notes_2",
  "break_option_notes_3",
  "rent_after_option_1",
  "option_taken_1",
  "option_notes_1",
  "rent_after_option_2",
  "option_taken_2",
  "option_notes_2",
  "rent_after_option_3",
  "option_taken_3",
  "option_notes_3",
  "rent_after_option_4",
  "option_taken_4",
  "option_notes_4",
  "rent_after_option_5",
  "option_taken_5",
  "option_notes_5",
  "deposit_type",
  "deposit_agreed",
  "subsidy",
  "competition_clause",
  "created_at",
  "updated_at",
  "currency_exchange_rates",
  "city_id",
  "district_id",
  "unit_type",
  "parent_unit_id"
]

const unitDefaultExportableFieldsEU = [
  "portfolio_ref_uuid",
  "unit_ref_uuid",
  "tenant_ref_uuid",
  "lonlat",
  "longitude",
  "latitude",
  "comment_other",
  "condominium",
  "rented_status_terminated",
  "rented_status_termination_effective_date",
  "comment_vacancy",
  "tenant_group",
  "tenant_corporate_group",
  "tenant_industry",
  "tenant_sub_industry",
  "tenant_main_customer",
  "tenant_address",
  "walt",
  "lease_signed_date",
  "rent_vat_tax_rate",
  "rent_parking_monthly",
  "arrear_amount",
  "arrear_since",
  "arrear_dunning_level",
  "last_increase_date",
  "break_option_notes_1",
  "break_option_notes_2",
  "break_option_notes_3",
  "rent_after_option_1",
  "option_taken_1",
  "option_notes_1",
  "rent_after_option_2",
  "option_taken_2",
  "option_notes_2",
  "rent_after_option_3",
  "option_taken_3",
  "option_notes_3",
  "rent_after_option_4",
  "option_taken_4",
  "option_notes_4",
  "rent_after_option_5",
  "option_taken_5",
  "option_notes_5",
  "deposit_type",
  "deposit_agreed",
  "created_at",
  "updated_at",
  "city_id",
  "district_id",
  "source_unit_type",
  "unit_type",
  "parent_unit_id",
  "com_base_rent",
  "com_rent_free",
  "com_cam",
  "com_utilities",
  "com_water",
  "com_real_estate_taxes",
  "com_insurance",
  "com_mgmt_fee",
  "com_janitorial",
  "com_reimbursements",
  "com_other",
  "mf_rent",
  "mf_subsidy",
  "subsidy_date",
  "mf_concessions",
  "mf_parking",
  "mf_storage",
  "mf_pet",
  "mf_cable_internet",
  "mf_utilities",
  "mf_water",
  "mf_other",
  "mf_trash",
  "mf_amenity",
  "mf_nru",
  "mf_reimbursements",
  "percentage_rent_amount",
  "percentage_rent_breakpoint",
  "percentage_rent_rate",
  "annual_sales",
  "lease_type",
  "expense_stop",
  "occupancy_cost",
  "tenant_improvement_allowance",
  "leasing_commission",
  "leasing_commision_percent",
  "month_to_month_flag"
]

export const defaultMappableFieldsObject = {
  unitEU: unitDefaultMappableFieldsEU,
  unitUS: unitDefaultMappableFieldsUS,
  assetEU: assetDefaultMappableFieldsEU,
  assetUS: assetDefaultMappableFieldsUS
}

export const defaultExportableFieldsObject = {
  unitEU: unitDefaultExportableFieldsEU,
  unitUS: unitDefaultExportableFieldsUS,
  assetEU: assetDefaultExportableFieldsEU,
  assetUS: assetDefaultExportableFieldsUS
}

export const EUCreateCompanyDefaultFields = {
  mappable_unit_fields: unitDefaultMappableFieldsEU,
  exportable_unit_fields: unitDefaultExportableFieldsEU,
  mappable_asset_fields: assetDefaultMappableFieldsEU,
  exportable_asset_fields: assetDefaultExportableFieldsEU
}

export const USCreateCompanyDefaultFields = {
  mappable_unit_fields: unitDefaultMappableFieldsUS,
  exportable_unit_fields: unitDefaultExportableFieldsUS,
  mappable_asset_fields: assetDefaultMappableFieldsUS,
  exportable_asset_fields: assetDefaultExportableFieldsUS
}
